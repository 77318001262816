const CompetenceIcon = (props) => (
<svg width="50px" height="50px" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" fill={props.color}>
 <defs>
  <symbol id="h" overflow="visible">
   <path d="m14.656-0.875c-0.76172 0.39844-1.5547 0.69531-2.375 0.89062-0.8125 0.19531-1.6641 0.29688-2.5469 0.29688-2.6562 0-4.7617-0.73828-6.3125-2.2188-1.5547-1.4883-2.3281-3.5039-2.3281-6.0469 0-2.5508 0.77344-4.5664 2.3281-6.0469 1.5508-1.4883 3.6562-2.2344 6.3125-2.2344 0.88281 0 1.7344 0.10156 2.5469 0.29688 0.82031 0.19922 1.6133 0.49609 2.375 0.89062v3.2969c-0.76172-0.51953-1.5117-0.89844-2.25-1.1406-0.74219-0.23828-1.5234-0.35938-2.3438-0.35938-1.4688 0-2.625 0.46875-3.4688 1.4062-0.83594 0.9375-1.25 2.2344-1.25 3.8906 0 1.6484 0.41406 2.9375 1.25 3.875 0.84375 0.9375 2 1.4062 3.4688 1.4062 0.82031 0 1.6016-0.11719 2.3438-0.35938 0.73828-0.23828 1.4883-0.61719 2.25-1.1406z"/>
  </symbol>
  <symbol id="c" overflow="visible">
   <path d="m10.719-8.7031c-0.33594-0.15625-0.66797-0.26953-1-0.34375-0.32422-0.082031-0.65625-0.125-1-0.125-0.98047 0-1.7344 0.32031-2.2656 0.95312-0.53125 0.625-0.79688 1.5273-0.79688 2.7031v5.5156h-3.8125v-11.969h3.8125v1.9688c0.48828-0.78125 1.0508-1.3477 1.6875-1.7031 0.64453-0.36328 1.4102-0.54688 2.2969-0.54688 0.13281 0 0.27344 0.007812 0.42188 0.015625 0.14453 0.011719 0.36328 0.03125 0.65625 0.0625z"/>
  </symbol>
  <symbol id="a" overflow="visible">
   <path d="m13.781-6.0156v1.0938h-8.9375c0.09375 0.89844 0.41406 1.5703 0.96875 2.0156 0.55078 0.44922 1.3281 0.67188 2.3281 0.67188 0.80078 0 1.625-0.11719 2.4688-0.35938 0.84375-0.23828 1.7109-0.59766 2.6094-1.0781v2.9531c-0.90625 0.33594-1.8125 0.58984-2.7188 0.76562s-1.8125 0.26562-2.7188 0.26562c-2.1562 0-3.8398-0.54688-5.0469-1.6406-1.1992-1.1016-1.7969-2.6484-1.7969-4.6406 0-1.9453 0.58594-3.4766 1.7656-4.5938 1.1758-1.125 2.8008-1.6875 4.875-1.6875 1.875 0 3.375 0.57031 4.5 1.7031 1.1328 1.125 1.7031 2.6367 1.7031 4.5312zm-3.9375-1.2656c0-0.72656-0.21484-1.3125-0.64062-1.75-0.41797-0.44531-0.96875-0.67188-1.6562-0.67188-0.74219 0-1.3438 0.21094-1.8125 0.625-0.46094 0.41797-0.74609 1.0156-0.85938 1.7969z"/>
  </symbol>
  <symbol id="m" overflow="visible">
   <path d="m7.2031-5.3906c-0.80469 0-1.4062 0.13672-1.8125 0.40625-0.39844 0.27344-0.59375 0.67188-0.59375 1.2031 0 0.48047 0.16016 0.85938 0.48438 1.1406 0.32031 0.27344 0.77344 0.40625 1.3594 0.40625 0.71875 0 1.3203-0.25391 1.8125-0.76562 0.48828-0.51953 0.73438-1.1719 0.73438-1.9531v-0.4375zm5.8438-1.4375v6.8281h-3.8594v-1.7656c-0.51172 0.71875-1.0898 1.2461-1.7344 1.5781-0.63672 0.33203-1.418 0.5-2.3438 0.5-1.2422 0-2.2461-0.35938-3.0156-1.0781-0.77344-0.72656-1.1562-1.6719-1.1562-2.8281 0-1.3945 0.47656-2.4219 1.4375-3.0781 0.96875-0.65625 2.4883-0.98438 4.5625-0.98438h2.25v-0.29688c0-0.60156-0.24219-1.0469-0.71875-1.3281-0.48047-0.28125-1.2266-0.42188-2.2344-0.42188-0.8125 0-1.5742 0.085937-2.2812 0.25-0.71094 0.15625-1.3672 0.40234-1.9688 0.73438v-2.9219c0.82031-0.19531 1.6445-0.34766 2.4688-0.45312 0.82031-0.10156 1.6484-0.15625 2.4844-0.15625 2.1562 0 3.7109 0.42969 4.6719 1.2812 0.95703 0.84375 1.4375 2.2266 1.4375 4.1406z"/>
  </symbol>
  <symbol id="b" overflow="visible">
   <path d="m6.0156-15.359v3.3906h3.9375v2.7344h-3.9375v5.0781c0 0.55469 0.10938 0.92969 0.32812 1.125 0.21875 0.19922 0.65625 0.29688 1.3125 0.29688h1.9688v2.7344h-3.2812c-1.5117 0-2.5859-0.3125-3.2188-0.9375-0.625-0.63281-0.9375-1.707-0.9375-3.2188v-5.0781h-1.9062v-2.7344h1.9062v-3.3906z"/>
  </symbol>
  <symbol id="l" overflow="visible">
   <path d="m9.9688-10.219v-6.4062h3.8594v16.625h-3.8594v-1.7344c-0.52344 0.71094-1.1016 1.2305-1.7344 1.5625-0.63672 0.32031-1.3711 0.48438-2.2031 0.48438-1.4688 0-2.6797-0.58203-3.625-1.75-0.94922-1.1758-1.4219-2.6875-1.4219-4.5312s0.47266-3.3477 1.4219-4.5156c0.94531-1.1758 2.1562-1.7656 3.625-1.7656 0.83203 0 1.5664 0.16797 2.2031 0.5 0.63281 0.32422 1.2109 0.83594 1.7344 1.5312zm-2.5156 7.75c0.82031 0 1.4453-0.29688 1.875-0.89062 0.42578-0.60156 0.64062-1.4727 0.64062-2.6094 0-1.1445-0.21484-2.0156-0.64062-2.6094-0.42969-0.59375-1.0547-0.89062-1.875-0.89062-0.8125 0-1.4336 0.29688-1.8594 0.89062-0.42969 0.59375-0.64062 1.4648-0.64062 2.6094 0 1.1367 0.21094 2.0078 0.64062 2.6094 0.42578 0.59375 1.0469 0.89062 1.8594 0.89062z"/>
  </symbol>
  <symbol id="k" overflow="visible">
   <path d="m8.2031-2.4688c0.82031 0 1.4453-0.29688 1.875-0.89062 0.42578-0.60156 0.64062-1.4727 0.64062-2.6094 0-1.1445-0.21484-2.0156-0.64062-2.6094-0.42969-0.59375-1.0547-0.89062-1.875-0.89062-0.82422 0-1.4531 0.30469-1.8906 0.90625-0.4375 0.59375-0.65625 1.4609-0.65625 2.5938 0 1.1367 0.21875 2.0078 0.65625 2.6094 0.4375 0.59375 1.0664 0.89062 1.8906 0.89062zm-2.5469-7.75c0.53125-0.69531 1.1133-1.207 1.75-1.5312 0.64453-0.33203 1.3828-0.5 2.2188-0.5 1.4688 0 2.6758 0.58984 3.625 1.7656 0.94531 1.168 1.4219 2.6719 1.4219 4.5156s-0.47656 3.3555-1.4219 4.5312c-0.94922 1.168-2.1562 1.75-3.625 1.75-0.83594 0-1.5742-0.16797-2.2188-0.5-0.63672-0.33203-1.2188-0.84766-1.75-1.5469v1.7344h-3.8125v-16.625h3.8125z"/>
  </symbol>
  <symbol id="j" overflow="visible">
   <path d="m0.26562-11.969h3.8281l3.2188 8.125 2.7344-8.125h3.8125l-5.0312 13.094c-0.5 1.332-1.0898 2.2656-1.7656 2.7969-0.66797 0.53125-1.5547 0.79688-2.6562 0.79688h-2.2188v-2.5h1.2031c0.64453 0 1.1133-0.10547 1.4062-0.3125 0.30078-0.21094 0.53125-0.57812 0.6875-1.1094l0.10938-0.34375z"/>
  </symbol>
  <symbol id="i" overflow="visible">
   <path d="m0.65625-15.953h3.9375l2.75 11.594 2.7344-11.594h3.9688l2.7344 11.594 2.75-11.594h3.9062l-3.75 15.953h-4.75l-2.8906-12.125-2.8594 12.125h-4.75z"/>
  </symbol>
  <symbol id="g" overflow="visible">
   <path d="m2.0156-15.953h11.094v3.1094h-6.9844v2.9688h6.5625v3.1094h-6.5625v3.6562h7.2188v3.1094h-11.328z"/>
  </symbol>
  <symbol id="z" overflow="visible">
   <path d="m8.3906-9.7656c0.65625 0 1.1484-0.14062 1.4844-0.42188 0.33203-0.28906 0.5-0.71094 0.5-1.2656 0-0.55078-0.16797-0.97266-0.5-1.2656-0.33594-0.28906-0.82812-0.4375-1.4844-0.4375h-2.2656v3.3906zm0.14062 6.9688c0.82031 0 1.4414-0.17188 1.8594-0.51562 0.41406-0.35156 0.625-0.88281 0.625-1.5938 0-0.6875-0.21094-1.2031-0.625-1.5469-0.40625-0.34375-1.0273-0.51562-1.8594-0.51562h-2.4062v4.1719zm3.8281-5.7344c0.88281 0.25 1.5664 0.72656 2.0469 1.4219 0.48828 0.6875 0.73438 1.5312 0.73438 2.5312 0 1.543-0.52344 2.6953-1.5625 3.4531-1.043 0.75-2.625 1.125-4.75 1.125h-6.8125v-15.953h6.1719c2.207 0 3.8047 0.33984 4.7969 1.0156 1 0.66797 1.5 1.7344 1.5 3.2031 0 0.78125-0.18359 1.4492-0.54688 2-0.36719 0.54297-0.89062 0.94531-1.5781 1.2031z"/>
  </symbol>
  <symbol id="y" overflow="visible">
   <path d="m0.10938-15.953h14.688v3.1094h-5.2812v12.844h-4.1094v-12.844h-5.2969z"/>
  </symbol>
  <symbol id="x" overflow="visible">
   <path d="m2.0156-15.953h4.1094v6.0781h6.0625v-6.0781h4.1094v15.953h-4.1094v-6.7656h-6.0625v6.7656h-4.1094z"/>
  </symbol>
  <symbol id="w" overflow="visible">
   <path d="m9.2969-13.25c-1.2617 0-2.2344 0.46484-2.9219 1.3906-0.6875 0.91797-1.0312 2.2188-1.0312 3.9062 0 1.668 0.34375 2.9648 1.0312 3.8906 0.6875 0.92969 1.6602 1.3906 2.9219 1.3906 1.2578 0 2.2344-0.46094 2.9219-1.3906 0.6875-0.92578 1.0312-2.2227 1.0312-3.8906 0-1.6875-0.34375-2.9883-1.0312-3.9062-0.6875-0.92578-1.6641-1.3906-2.9219-1.3906zm0-2.9844c2.5625 0 4.5664 0.73438 6.0156 2.2031 1.457 1.4688 2.1875 3.4961 2.1875 6.0781 0 2.5742-0.73047 4.5938-2.1875 6.0625-1.4492 1.4688-3.4531 2.2031-6.0156 2.2031s-4.5742-0.73438-6.0312-2.2031c-1.4492-1.4688-2.1719-3.4883-2.1719-6.0625 0-2.582 0.72266-4.6094 2.1719-6.0781 1.457-1.4688 3.4688-2.2031 6.0312-2.2031z"/>
  </symbol>
  <symbol id="e" overflow="visible">
   <path d="m2.0156-15.953h6.8125c2.0312 0 3.5859 0.45312 4.6719 1.3594 1.0938 0.89844 1.6406 2.1797 1.6406 3.8438 0 1.6797-0.54688 2.9688-1.6406 3.875-1.0859 0.89844-2.6406 1.3438-4.6719 1.3438h-2.7031v5.5312h-4.1094zm4.1094 2.9844v4.4531h2.2656c0.80078 0 1.4141-0.19141 1.8438-0.57812 0.4375-0.38281 0.65625-0.9375 0.65625-1.6562 0-0.70703-0.21875-1.2539-0.65625-1.6406-0.42969-0.38281-1.043-0.57812-1.8438-0.57812z"/>
  </symbol>
  <symbol id="v" overflow="visible">
   <path d="m13.109-15.438v3.375c-0.875-0.39453-1.7305-0.69141-2.5625-0.89062-0.83594-0.19531-1.6211-0.29688-2.3594-0.29688-0.99219 0-1.7188 0.13672-2.1875 0.40625-0.46875 0.27344-0.70312 0.6875-0.70312 1.25 0 0.42969 0.15625 0.76172 0.46875 1 0.32031 0.24219 0.89844 0.44922 1.7344 0.625l1.75 0.34375c1.7695 0.35547 3.0312 0.89844 3.7812 1.625 0.75 0.73047 1.125 1.7617 1.125 3.0938 0 1.7617-0.52734 3.0742-1.5781 3.9375-1.043 0.85547-2.6328 1.2812-4.7656 1.2812-1.0117 0-2.0273-0.10156-3.0469-0.29688-1.0234-0.1875-2.043-0.46875-3.0625-0.84375v-3.4688c1.0195 0.53125 2.0039 0.9375 2.9531 1.2188 0.95703 0.27344 1.875 0.40625 2.75 0.40625 0.90625 0 1.5938-0.14453 2.0625-0.4375 0.47656-0.30078 0.71875-0.73438 0.71875-1.2969 0-0.48828-0.16406-0.86719-0.48438-1.1406-0.32422-0.26953-0.96875-0.51562-1.9375-0.73438l-1.5938-0.35938c-1.5938-0.33203-2.7617-0.875-3.5-1.625-0.74219-0.75-1.1094-1.7578-1.1094-3.0312 0-1.582 0.50781-2.8008 1.5312-3.6562 1.0312-0.85156 2.5078-1.2812 4.4375-1.2812 0.875 0 1.7734 0.070313 2.7031 0.20312 0.92578 0.125 1.8828 0.32422 2.875 0.59375z"/>
  </symbol>
  <symbol id="f" overflow="visible">
   <path d="m2.0156-15.953h4.1094v12.844h7.2188v3.1094h-11.328z"/>
  </symbol>
  <symbol id="u" overflow="visible">
   <path d="m9.7031-16.625v2.5156h-2.1094c-0.54297 0-0.92188 0.10156-1.1406 0.29688-0.21094 0.19922-0.3125 0.53906-0.3125 1.0156v0.82812h3.2656v2.7344h-3.2656v9.2344h-3.8281v-9.2344h-1.8906v-2.7344h1.8906v-0.82812c0-1.3008 0.36328-2.2656 1.0938-2.8906 0.72656-0.625 1.8516-0.9375 3.375-0.9375z"/>
  </symbol>
  <symbol id="d" overflow="visible">
   <path d="m7.5312-9.5156c-0.84375 0-1.4922 0.30859-1.9375 0.92188-0.44922 0.60547-0.67188 1.4805-0.67188 2.625 0 1.1484 0.22266 2.0273 0.67188 2.6406 0.44531 0.60547 1.0938 0.90625 1.9375 0.90625 0.83203 0 1.4688-0.30078 1.9062-0.90625 0.44531-0.61328 0.67188-1.4922 0.67188-2.6406 0-1.1445-0.22656-2.0195-0.67188-2.625-0.4375-0.61328-1.0742-0.92188-1.9062-0.92188zm0-2.7344c2.0508 0 3.6562 0.55859 4.8125 1.6719 1.1641 1.1055 1.75 2.6406 1.75 4.6094s-0.58594 3.5117-1.75 4.625c-1.1562 1.1055-2.7617 1.6562-4.8125 1.6562-2.0625 0-3.6797-0.55078-4.8438-1.6562-1.168-1.1133-1.75-2.6562-1.75-4.625s0.58203-3.5039 1.75-4.6094c1.1641-1.1133 2.7812-1.6719 4.8438-1.6719z"/>
  </symbol>
  <symbol id="t" overflow="visible">
   <path d="m12.922-9.9688c0.48828-0.75 1.0625-1.3164 1.7188-1.7031 0.66406-0.38281 1.3984-0.57812 2.2031-0.57812 1.375 0 2.4219 0.42969 3.1406 1.2812 0.71875 0.84375 1.0781 2.0742 1.0781 3.6875v7.2812h-3.8438v-6.2344c0.007812-0.09375 0.015625-0.1875 0.015625-0.28125v-0.4375c0-0.84375-0.125-1.457-0.375-1.8438-0.25-0.38281-0.65234-0.57812-1.2031-0.57812-0.73047 0-1.293 0.30469-1.6875 0.90625-0.38672 0.59375-0.58984 1.4609-0.60938 2.5938v5.875h-3.8438v-6.2344c0-1.3203-0.11719-2.1758-0.34375-2.5625-0.23047-0.38281-0.63672-0.57812-1.2188-0.57812-0.73047 0-1.2969 0.30469-1.7031 0.90625-0.39844 0.60547-0.59375 1.4648-0.59375 2.5781v5.8906h-3.8438v-11.969h3.8438v1.75c0.46875-0.66406 1.0039-1.1719 1.6094-1.5156 0.61328-0.34375 1.2891-0.51562 2.0312-0.51562 0.82031 0 1.5508 0.19922 2.1875 0.59375 0.63281 0.39844 1.1133 0.96094 1.4375 1.6875z"/>
  </symbol>
  <symbol id="s" overflow="visible">
   <path d="m13.859-7.2812v7.2812h-3.8438v-5.5469c0-1.0508-0.027344-1.7695-0.078125-2.1562-0.042969-0.39453-0.12109-0.6875-0.23438-0.875-0.14844-0.25-0.35156-0.44141-0.60938-0.57812-0.25-0.14453-0.54297-0.21875-0.875-0.21875-0.79297 0-1.418 0.3125-1.875 0.9375-0.46094 0.61719-0.6875 1.4648-0.6875 2.5469v5.8906h-3.8125v-16.625h3.8125v6.4062c0.58203-0.69531 1.1953-1.207 1.8438-1.5312 0.64453-0.33203 1.3594-0.5 2.1406-0.5 1.3828 0 2.4297 0.42969 3.1406 1.2812 0.71875 0.84375 1.0781 2.0742 1.0781 3.6875z"/>
  </symbol>
  <symbol id="r" overflow="visible">
   <path d="m2.0156-15.953h4.5781l5.8125 10.938v-10.938h3.8906v15.953h-4.5938l-5.7969-10.938v10.938h-3.8906z"/>
  </symbol>
  <symbol id="q" overflow="visible">
   <path d="m1.7031-4.6562v-7.3125h3.8438v1.2031c0 0.64844-0.007813 1.4609-0.015625 2.4375v1.9688c0 0.96094 0.023438 1.6523 0.078125 2.0781 0.050781 0.41797 0.13281 0.72656 0.25 0.92188 0.15625 0.25 0.35938 0.44531 0.60938 0.57812 0.25781 0.13672 0.55078 0.20312 0.875 0.20312 0.80078 0 1.4258-0.30469 1.875-0.92188 0.45703-0.61328 0.6875-1.4688 0.6875-2.5625v-5.9062h3.8281v11.969h-3.8281v-1.7344c-0.57422 0.69922-1.1836 1.2148-1.8281 1.5469-0.64844 0.33203-1.3555 0.5-2.125 0.5-1.3867 0-2.4453-0.42188-3.1719-1.2656-0.71875-0.85156-1.0781-2.0859-1.0781-3.7031z"/>
  </symbol>
  <symbol id="p" overflow="visible">
   <path d="m13.859-7.2812v7.2812h-3.8438v-5.5781c0-1.0312-0.027344-1.7383-0.078125-2.125-0.042969-0.39453-0.12109-0.6875-0.23438-0.875-0.14844-0.25-0.35156-0.44141-0.60938-0.57812-0.25-0.14453-0.54297-0.21875-0.875-0.21875-0.79297 0-1.418 0.3125-1.875 0.9375-0.46094 0.61719-0.6875 1.4648-0.6875 2.5469v5.8906h-3.8125v-11.969h3.8125v1.75c0.58203-0.69531 1.1953-1.207 1.8438-1.5312 0.64453-0.33203 1.3594-0.5 2.1406-0.5 1.3828 0 2.4297 0.42969 3.1406 1.2812 0.71875 0.84375 1.0781 2.0742 1.0781 3.6875z"/>
  </symbol>
  <symbol id="o" overflow="visible">
   <path d="m1.8438-11.969h3.8125v11.75c0 1.6016-0.38672 2.8281-1.1562 3.6719-0.76172 0.84375-1.8711 1.2656-3.3281 1.2656h-1.8906v-2.5h0.65625c0.72656 0 1.2266-0.16797 1.5-0.5 0.26953-0.32422 0.40625-0.96875 0.40625-1.9375zm0-4.6562h3.8125v3.125h-3.8125z"/>
  </symbol>
  <symbol id="n" overflow="visible">
   <path d="m11.5-11.594v3.125c-0.52344-0.35156-1.043-0.61328-1.5625-0.78125-0.52344-0.17578-1.0625-0.26562-1.625-0.26562-1.0742 0-1.9062 0.3125-2.5 0.9375s-0.89062 1.4961-0.89062 2.6094c0 1.1172 0.29688 1.9844 0.89062 2.6094s1.4258 0.9375 2.5 0.9375c0.59375 0 1.1562-0.085937 1.6875-0.26562 0.53906-0.17578 1.0391-0.44141 1.5-0.79688v3.125c-0.59375 0.23047-1.1992 0.39453-1.8125 0.5-0.61719 0.11328-1.2344 0.17188-1.8594 0.17188-2.1562 0-3.8438-0.55078-5.0625-1.6562-1.2188-1.1133-1.8281-2.6562-1.8281-4.625s0.60938-3.5039 1.8281-4.6094c1.2188-1.1133 2.9062-1.6719 5.0625-1.6719 0.625 0 1.2383 0.058594 1.8438 0.17188 0.61328 0.10547 1.2227 0.26562 1.8281 0.48438z"/>
  </symbol>
 </defs>
 <g>
  <path d="m199.94 173.16-16.188 90.562c-0.52344 2.9844 0.53516 6.0312 2.8008 8.0469l26.25 23.492v0.003906c3.3008 2.9023 8.2461 2.9023 11.551 0l26.25-22.531c2.3633-1.9258 3.5586-4.9414 3.1484-7.9648l-15.27-91.875c-5.7617 1.1914-11.617 1.8516-17.5 1.9688l14.613 87.852-16.844 14.395-16.887-15.137 15.531-86.977c-5.8633-0.078125-11.707-0.69531-17.457-1.8359z"/>
  <path d="m218.75 157.5c20.887 0 40.918-8.2969 55.684-23.066 14.77-14.766 23.066-34.797 23.066-55.684s-8.2969-40.918-23.066-55.684c-14.766-14.77-34.797-23.066-55.684-23.066s-40.918 8.2969-55.684 23.066c-14.77 14.766-23.066 34.797-23.066 55.684s8.2969 40.918 23.066 55.684c14.766 14.77 34.797 23.066 55.684 23.066zm0-140c16.246 0 31.824 6.4531 43.309 17.941 11.488 11.484 17.941 27.062 17.941 43.309s-6.4531 31.824-17.941 43.309c-11.484 11.488-27.062 17.941-43.309 17.941s-31.824-6.4531-43.309-17.941c-11.488-11.484-17.941-27.062-17.941-43.309s6.4531-31.824 17.941-43.309c11.484-11.488 27.062-17.941 43.309-17.941z"/>
  <path d="m500.85 172.99c-5.7617 1.1875-11.621 1.8477-17.5 1.9688l14.613 87.852-16.715 14.305-16.887-15.137 15.531-86.977c-5.8789-0.078125-11.734-0.69141-17.5-1.8359l-16.145 90.562c-0.52344 2.9844 0.53516 6.0312 2.8008 8.0469l26.25 23.492v0.003906c3.3008 2.9023 8.2461 2.9023 11.551 0l26.25-22.531c2.3281-2 3.4375-5.0703 2.9297-8.0938z"/>
  <path d="m481.25 157.5c20.887 0 40.918-8.2969 55.684-23.066 14.77-14.766 23.066-34.797 23.066-55.684s-8.2969-40.918-23.066-55.684c-14.766-14.77-34.797-23.066-55.684-23.066s-40.918 8.2969-55.684 23.066c-14.77 14.766-23.066 34.797-23.066 55.684s8.2969 40.918 23.066 55.684c14.766 14.77 34.797 23.066 55.684 23.066zm0-140c16.246 0 31.824 6.4531 43.309 17.941 11.488 11.484 17.941 27.062 17.941 43.309s-6.4531 31.824-17.941 43.309c-11.484 11.488-27.062 17.941-43.309 17.941s-31.824-6.4531-43.309-17.941c-11.488-11.484-17.941-27.062-17.941-43.309s6.4531-31.824 17.941-43.309c11.484-11.488 27.062-17.941 43.309-17.941z"/>
  <path d="m328.12 356.56-17.5 99.664c-0.51562 2.9414 0.50781 5.9453 2.7109 7.9609l30.625 27.914c3.3203 2.9531 8.3203 2.9531 11.641 0l30.625-26.773v-0.003906c2.2812-1.9883 3.375-5.0156 2.8867-8.0039l-16.539-101.06c-5.7617 1.2305-11.613 1.9922-17.5 2.2734l15.836 96.863-20.91 18.504-21.305-19.426 16.93-95.723c-5.8828-0.25781-11.734-0.98828-17.5-2.1875z"/>
  <path d="m258.12 249.38c0 24.367 9.6797 47.734 26.91 64.965 17.23 17.23 40.598 26.91 64.965 26.91s47.734-9.6797 64.965-26.91c17.23-17.23 26.91-40.598 26.91-64.965s-9.6797-47.734-26.91-64.965c-17.23-17.23-40.598-26.91-64.965-26.91s-47.734 9.6797-64.965 26.91c-17.23 17.23-26.91 40.598-26.91 64.965zm91.875-74.375c19.727 0 38.645 7.8359 52.59 21.785 13.949 13.945 21.785 32.863 21.785 52.59s-7.8359 38.645-21.785 52.59c-13.945 13.949-32.863 21.785-52.59 21.785s-38.645-7.8359-52.59-21.785c-13.949-13.945-21.785-32.863-21.785-52.59s7.8359-38.645 21.785-52.59c13.945-13.949 32.863-21.785 52.59-21.785z"/>
  <path d="m429.67 324.19c-6.0938 6.4922-12.957 12.223-20.43 17.062h10.762c16.516 0.066406 32.676 4.8047 46.613 13.672 13.934 8.8633 25.078 21.492 32.137 36.422 4.6562-4.0039 10.57-6.25 16.711-6.3438-7.6914-16.785-19.672-31.242-34.734-41.922-15.062-10.676-32.672-17.191-51.059-18.891z"/>
  <path d="m447.08 546.88 0.74219-4.375h-249.38c-3.293-0.023438-5.9492-2.6992-5.9492-5.9922v-108.2c0.11719-23.129 9.3867-45.273 25.781-61.59 16.398-16.312 38.59-25.473 61.719-25.473h10.98c-7.4727-4.8398-14.336-10.57-20.43-17.062-26.027 2.3516-50.246 14.324-67.914 33.582-17.672 19.254-27.523 44.41-27.637 70.543v108.2c0.011719 6.2344 2.4961 12.211 6.9102 16.613 4.4141 4.4062 10.391 6.8789 16.629 6.8789h249.77c-1.5156-4.1992-1.9375-8.7188-1.2227-13.125z"/>
  <path d="m587.56 456.09-43.75-6.6953-19.645-41.867c-1.4414-3.0703-4.5273-5.0312-7.918-5.0312s-6.4766 1.9609-7.918 5.0312l-19.645 41.867-43.75 6.6953c-3.2344 0.5-5.9219 2.7617-6.9609 5.8672-1.043 3.1016-0.26172 6.5273 2.0156 8.875l31.895 32.68-7.5703 46.289h0.003906c-0.53516 3.3047 0.86328 6.625 3.5977 8.5508 2.7383 1.9258 6.332 2.1211 9.2617 0.50781l39.023-21.57 39.023 21.57h0.003907c2.9297 1.6133 6.5273 1.418 9.2617-0.50781 2.7383-1.9258 4.1328-5.2461 3.6016-8.5508l-7.5234-46.289 31.895-32.68h-0.003906c2.2812-2.3477 3.0625-5.7734 2.0195-8.875-1.0391-3.1055-3.7266-5.3672-6.9609-5.8672zm-42.57 38.281c-1.9375 1.9922-2.8125 4.7852-2.3594 7.5234l5.3359 32.898-27.344-15.133c-2.6406-1.4648-5.8477-1.4648-8.4883 0l-27.52 15.227 5.3828-32.902c0.45312-2.7422-0.42578-5.5352-2.3633-7.5273l-23.102-23.668 31.5-4.8125h0.003906c2.8867-0.44141 5.3672-2.2969 6.6055-4.9414l13.605-29.184 13.695 29.227c1.2383 2.6484 3.7148 4.5039 6.6055 4.9414l31.543 4.8125z"/>
  <path d="m629.56 289.8c-1.0391-3.1016-3.7227-5.3633-6.957-5.8633l-22.574-3.457-10.195-21.875c-1.4961-3.0156-4.5938-4.9023-7.9609-4.8555-3.3906 0-6.4766 1.9609-7.918 5.0312l-10.195 21.875-22.574 3.457c-3.2344 0.5-5.9219 2.7617-6.9609 5.8672-1.043 3.1016-0.26172 6.5273 2.0156 8.875l16.582 17.02-3.9375 24.148c-0.53125 3.3047 0.86719 6.625 3.6016 8.5508s6.332 2.1211 9.2617 0.50781l20.125-11.113 20.082 11.113c2.9297 1.6133 6.5234 1.418 9.2617-0.50781 2.7344-1.9258 4.1328-5.2461 3.6016-8.5508l-3.9375-24.148 16.582-17.02h-0.003906c2.3789-2.3672 3.1953-5.8828 2.1016-9.0547zm-34.258 16.844c-1.9375 1.9922-2.8125 4.7812-2.3594 7.5234l1.75 10.719-8.75-4.7266v0.003906c-2.6406-1.4648-5.8477-1.4648-8.4883 0l-8.75 4.7266 1.75-10.719v-0.003906c0.44922-2.7422-0.42578-5.5312-2.3633-7.5234l-7.7891-7.9609 10.367-1.5742 0.003906-0.003906c2.9688-0.37109 5.543-2.2344 6.8242-4.9414l4.375-9.0547 4.375 9.0547c1.2383 2.6445 3.7148 4.5 6.6055 4.9414l10.367 1.5742z"/>
  <path d="m158.81 283.94-22.574-3.457-10.195-21.875c-1.4922-3-4.5664-4.8867-7.918-4.8555-3.3906 0-6.4766 1.9609-7.918 5.0312l-10.195 21.875-22.574 3.2812c-3.2344 0.5-5.9219 2.7617-6.9609 5.8672-1.043 3.1016-0.26172 6.5273 2.0156 8.875l16.582 17.02-3.9375 24.148v0.003906c-0.53125 3.3008 0.86719 6.6211 3.6016 8.5469 2.7344 1.9258 6.332 2.125 9.2617 0.50781l20.125-11.113 20.082 11.113c2.9297 1.6172 6.5234 1.418 9.2617-0.50781 2.7344-1.9258 4.1328-5.2461 3.6016-8.5469l-3.9375-24.148 16.582-17.02-0.003906-0.003906c2.2812-2.3398 3.0625-5.7578 2.0352-8.8594-1.0312-3.1016-3.707-5.3711-6.9336-5.8828zm-27.258 22.707c-1.9375 1.9922-2.8125 4.7812-2.3594 7.5234l1.75 10.719-8.75-4.7266v0.003906c-2.6406-1.4648-5.8477-1.4648-8.4883 0l-8.75 4.7266 1.75-10.719v-0.003906c0.44922-2.7422-0.42578-5.5312-2.3633-7.5234l-7.4375-7.9648 10.367-1.5742c2.8438-0.48047 5.2656-2.3281 6.4766-4.9414l4.375-9.0547 4.375 9.0547c1.2383 2.6445 3.7148 4.5 6.6055 4.9414l10.367 1.5742z"/>
  <path d="m422.14 166.25h19.074c-9.3945-4.3125-18.035-10.102-25.594-17.148-5.5117 0.39844-10.973 1.3047-16.32 2.7109 7.8477 3.9336 15.191 8.7969 21.875 14.48z"/>
  <path d="m258.78 166.25h20.211c6.6836-5.7031 14.027-10.578 21.875-14.523-5.4023-1.4023-10.922-2.2969-16.492-2.6719-7.5547 7.0664-16.199 12.871-25.594 17.195z"/>
  <path d="m546.88 149.05c-7.5547 7.0664-16.199 12.871-25.594 17.195h19.074c19.078-0.035156 37.387 7.5 50.914 20.953 13.523 13.453 21.16 31.723 21.23 50.797v26.688l12.73 1.9258c1.625 0.26953 3.2227 0.69531 4.7695 1.2695v-29.883c-0.074219-22.578-8.6836-44.293-24.102-60.789-15.418-16.496-36.5-26.555-59.023-28.156z"/>
  <path d="m630 324.05v-2.8438l-0.39453 0.39453z"/>
  <path d="m70 324.01 0.39453-2.4062-0.39453-0.39453z"/>
  <path d="m87.5 264.69v-26.688c0.070312-19.074 7.707-37.344 21.23-50.797 13.527-13.453 31.836-20.988 50.914-20.953h19.074c-9.3945-4.3125-18.035-10.102-25.594-17.148-22.516 1.5977-43.594 11.648-59.008 28.137-15.414 16.488-24.031 38.191-24.117 60.762v29.926c1.5469-0.57422 3.1445-0.99609 4.7695-1.2695z"/>
  <path d="m186.07 350c5.4102-6.4336 11.465-12.297 18.066-17.5h-36.531l0.74219 4.375h0.003906c0.71484 4.4062 0.29297 8.9258-1.2266 13.125z"/>
  <path d="m532.35 332.5h-36.531c6.6016 5.2031 12.656 11.066 18.066 17.5h18.945c-1.5195-4.1992-1.9414-8.7188-1.2266-13.125z"/>
 </g>
</svg>
   
        );
      
      export default CompetenceIcon;