import './Services.css';
import React from 'react';
import Services1 from '../../components/Services/Services';
import RequestForm from '../../components/RequestForm/RequestForm';
import PageHeader from '../../components/PageHeader/PageHeader';

function Services() {
  return (
    <div className="service"> 
        <PageHeader title="Services" url="SERVICES"/>
        <Services1 />
        <RequestForm />
    </div>
  );
}

export default Services;