import './PageHeader.css';
import React from 'react'
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';

function PageHeader(props) {
  return (
    <div className="pageHeader" > 
      <div className='pageHeader-content' style={{background:'#007bffcc', height:300}}>
        <Fade bottom>
          <div style={{fontSize: 40, fontWeight:700, paddingTop:100, paddingBottom: 10}}>{props.title}</div>
        </Fade>
        <Fade bottom>
          <div className='pageHeader-url'>
              <Link to='/' style={{color:'white'}}>HOME</Link> > {props.url} >
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default PageHeader;