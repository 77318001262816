import './Career.css';
import React from 'react'
import PageHeader from '../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade";

function Career() {
  return (
    <div className="career"> 
        <PageHeader title='Career' url='CAREER' />
        <div className="why-us">
            <div className="why-us-left">
                <div className='center-wrapper'>
                    <Fade bottom>
                        <div className='why-us-element-text'>
                        <div className='why-us-left-title'>
                          Work. Grow. Stand Out.
                        </div>
                        Like our customers, our drivers are the most important part of our business and we ensure that we recruit the finest, 
                        excellently skilled, and experienced drivers to join our team. <br/><br/>
                        We are always seeking certified drivers and experienced personnel 
                        who are true to their words and are willing to pour their passion into 
                        helping us maintain the quality of our service delivery. 
                        If you find yourself as the perfect fit for this job, kindly contact us today. <br/><br/>
                        Our recruitment process is designed to allow only the best-qualified candidates 
                        to gain the chance of working with us.
                        </div>
                    </Fade>
                </div>
            </div>
            <div className="why-us-right-content">
                 <Fade bottom>
                <div className="why-us-right">                
                    <img src="./img/career.jpg" alt="Image" width="400px"/>
                    <div className={`why-us-element-title`} style={{marginTop:20, maxWidth:400}}>
                      You can find numerous opportunities with us as we grow. At the moment, the open positions include:
                    </div>
                    <div className={`why-us-element-text`} style={{width:350, marginBottom:20, lineHeight: 2.5}}>
                        1. School Bus Drivers <br/>
                        2. 7D Drivers<br/>
                        3. Bus Monitor<br/>
                        4. Lead Technician<br/>
                    </div>
                    <Link to='/contact'>
                        <button  className="slider-button about-button">
                          SEND US AN APPLICATION TODAY!
                        </button>
                    </Link>
                </div>
                </Fade>
            </div>
        </div>
    </div>
  );
}

export default Career;