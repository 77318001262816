import React, { useEffect, useState } from 'react';
import TestiMonialsDetails from '../TestiMonialsDetails/TestiMonialsDetails'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import userPic from '../../image/user-one.png';
import './TestiMonials.css'
import Fade from "react-reveal/Fade";

const TestiMonials = () => {
  
    const testiMonials = [
        {
            name: 'Edwin F.',
            description: 'ABT has helped out with the challenge of getting our kids to school every day. There so much to be done in a single day and they are excellently handling the most important tasks of my everyday life, my kids.',
            address: 'United States',
            img: './img/person_1.jpg'
        },
        {
            name: 'Phil O.',
            description: "They've been doing a great job with the kids. It's far less challenging to have the transport experts from ABT handle the kids, get them in line, and take them home every day. I'm happy we are working with them.",
            address: 'United States',
            img: './img/person_2.jpg'
        },
        {
            name: 'Adams B.',
            description: "Quite an interesting experience with America Brightness so far. My adorable kids makes to school and back every day without a scratch. I really don't have a cause for worry since they've never reported any bullying cases or whatsoever.",
            address: 'United States',
            img: './img/person_3.jpg'
        },
        {
            name: 'Robert G.',
            description: 'I actually blocked out my calendar for appointments with my doctor and it seems like I and the experts from ABT share the same calendar. They are always on time for my appointments and bring me back home smoothly.',
            address: 'United States',
            img: './img/person_4.jpg'
        },
    ]
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            800: {
                items: 3
            }
        }
    };
    return (
        <section id="testimonial" className="testimonials pt-70 pb-70">
            <div className="container mt-5">
                <Fade bottom>
                    <div className="text-center">
                        <h3 className="sectionTitle">Words From Our Clients</h3>
                    </div>
                </Fade>
                <Fade bottom>
                    <p className="text-center section-text">Read what our clients have to say about our services.</p>
                </Fade>
                <div className="row testimonial-content">
                    <div className="col-md-12">
                        <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
                            {
                                testiMonials.length === 0 ?
                                    <div class="item">
                                        <div class="shadow-effect">
                                            <img class="img-circle" src='https://i.ibb.co/10SYccm/1552313010-354215-noticia-normal.jpg' />

                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                        <div class="testimonial-name">
                                            <h5>Rajon Rony</h5>
                                            <small>ITALY</small>
                                        </div>
                                    </div> :
                                    testiMonials.map(testiMonialDetail => {
                                        return (
                                            <TestiMonialsDetails testiMonialDetail={testiMonialDetail} key={testiMonialDetail._key} />

                                        )
                                    })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestiMonials;