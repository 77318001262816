import './About.css';
import React from 'react'
import PageHeader from '../../components/PageHeader/PageHeader';
import Fade from "react-reveal/Fade";
import { Link } from 'react-router-dom';

function About() {
  return (
    <div className="about"> 
        <PageHeader title='About Us' url='ABOUT US' />
        <div className="why-us">
            <div className="why-us-left">
                <div className='center-wrapper'>
                    <Fade bottom>
                        <div className='why-us-left-title'>
                            Welcome to ABT
                        </div>
                    </Fade>
                </div>
                <div className='center-wrapper'>
                    <Fade bottom>
                        <div className='why-us-element-text'>
                            America Brightness Transport is a fast-growing student transport organization based in Boston, Massachusetts. 
                            We are focused on providing safe, smooth, and reliable transportation services for school children with special needs. 
                            In the United States, over 10 billion trips are completed each year by school transport services. 
                            With the large volume of trips completed yearly, it is fairly difficult to show enough care to students with special needs. 
                            This is why we are focusing on these unique sets of children around Boston. 
                            We aim to offer optimum care, attention, and safety for these special kids. 
                            There is nothing as good as being able to compete in today's world. 
                            Health challenges are putting most of the great minds at a competitive disadvantage 
                            and we are here as a remedy to help these special children attend schools seamlessly 
                            and attain the heights that they envision. With our standard vehicles, 
                            we cruise the roads of Boston with a primary aim of making the dreams of every kid and parent in Boston come through.
                            For seniors, also, the competition doesn't give regard to age. 
                            Most who live with regular appointments with their doctors and visits to healthcare facilities wouldn't be more grateful 
                            for our non emergency medical transport service. 
                            Within and around Boston, we are always available for bookings and prompt arrivals to your home and healthcare facilities.
                        </div>
                    </Fade>
                </div>
            </div>
            <div className="why-us-right-content">
                 <Fade bottom>
                <div className="why-us-right">                
                    <img src="./img/2.jpg" alt="Image" width="400px" height='250px' />
                    <div className={`why-us-element-title`} style={{marginTop:20}}>
                        Our Story
                    </div>
                    <div className={`why-us-element-text`} style={{width:350, marginBottom:20}}>
                        To hundreds of kids with special needs, their parents, and educational institutions striving to foster inclusive learning, 
                        we feel the challenges that the entire population is facing to make humanity great. 
                        With seamless school transport, we join hands to help kids, irrespective of their physical status, 
                        acquire the best deserving education.
                    </div>
                    <Link to='/contact'>
                        <button  className="slider-button about-button">
                            Contact Us
                        </button>
                    </Link>
                </div>
                </Fade>
            </div>
        </div>
        <div className="why-us our-team">
            <Fade bottom>
                <div className="why-us-left about-team ">
                    <img src='./img/aboutteam.png' />
                </div>
            </Fade>
            <div className="why-us-left">
                <div className='center-wrapper'>
                    <Fade bottom>
                        <div className='why-us-left-title'>
                            Our Team
                        </div>
                    </Fade>
                </div>
                <div className='center-wrapper'>
                    <Fade bottom>
                        <div className='why-us-element-text'>
                            At America Brightness, we pride ourselves with a team of well trained, experienced, friendly, 
                            and caring experts in relevant areas. 
                            Our highly trained drivers make school transportation smooth and safe. 
                            Our safety and maintenance team work tirelessly to ensure that every vehicle and equipment are in excellent 
                            condition at all times. Our quality assurance team is the never-give-up persons who are focused on ensuring that 
                            we provide the best quality service that remains unmatched elsewhere. 
                            Our management team is always on the lookout to make sure that everything is in order. 
                            We focus on hiring only the finest drivers, engineers, and maintenance experts in the industry.
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    </div>
  );
}

export default About;