import React from "react";
import './Footer.css';
import { MdLocationOn, MdHomeRepairService, MdContactMail } from "react-icons/md";
import { FaPhoneAlt, FaInstagramSquare, FaUserShield } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { AiTwotoneHome, AiFillTwitterCircle } from "react-icons/ai";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { GrPinterest } from "react-icons/gr";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import { BiDetail } from "react-icons/bi";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { data } from "../../data";

function Footer() {
  return (
    <div className="footer" >
        <div className="footer-main">
            <div className="footer-main-side">
                <div className="footer-element">
                    <div className="footer-element-header">Have a Questions?</div>
                    {/* <div className="footer-element-content" style={{width:230}}><MdLocationOn />	5 Cabot Place, Stoughton MA 02072</div> */}
                    <div className="a-content"><a href={`tel:${data.phoneNumber}`}><FaPhoneAlt/> {data.phoneNumber}</a></div>
                    <div className="a-content"><a href="mailto:support@abttrans.com"><HiMail />	support@abttrans.com</a></div>
                </div>
                <div className="footer-element">
                    <div className="footer-element-header">About</div>
                    <div className="footer-element-content" style={{width:180}}>We are a fast-growing student transport organization based in Boston, MA. We are focused on providing safe, smooth, and reliable transportation services for all school children, as well as those with special needs, and nonemergency medical appointments.</div>
                </div>
            </div>
            <div className="footer-main-side footer-main-side-bottom">
                <div className="footer-element" style={{width:180}}>
                    <div className="footer-element-header">Links</div>
                    <div className="footer-element-content footer-element-content-link"><Link to='/'><AiTwotoneHome/><span class="link-text">Home</span></Link></div>
                    <div className="footer-element-content footer-element-content-link"><Link to='/about'><BiDetail/><span class="link-text">About</span></Link></div>
                    <div className="footer-element-content footer-element-content-link"><Link to='/services'><MdHomeRepairService/><span class="link-text">Services</span></Link></div>
                    <div className="footer-element-content footer-element-content-link"><Link to='/contact'><MdContactMail/><span class="link-text">Contact</span></Link></div>
                    <div className="footer-element-content footer-element-content-link"><Link to='/terms'><FaUserShield/><span class="link-text">Terms of Use</span></Link></div>
                </div>
                <div className="footer-element">
                    <div className="footer-element-header">Subscribe Us!</div>
                    <div className="footer-element-content"><input type='email' placeholder="Enter Email Address" /></div>
                    <div className="footer-element-content"><button>Subscribe</button></div>
                    <Fade bottom>
                        <div className="footer-element-header" style={{marginTop:40}}>Connect With Us</div>
                    </Fade>
                    <div className="footer-element-content footer-element-content-social" >
                        <Fade bottom>
                            <div className="social-icons">
                                <a href="https://twitter.com/AbtBrightness"><AiFillTwitterCircle size={32} /></a>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className="social-icons">
                                <a href="https://www.facebook.com/contactABT"><BsFacebook size={28} /></a>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className="social-icons">
                                <a href="https://www.instagram.com/abtransportation4u"><FaInstagramSquare size={29} /></a>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className="social-icons">
                                <a href="https://www.linkedin.com/in/america-brightness-transportation-84858219b/"><BsLinkedin size={27} /></a>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className="social-icons">
                                <a href="https://www.pinterest.com"><GrPinterest size={28} /></a>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className="social-icons">
                                <a href="https://www.youtube.com/channel/UCKLyFlaF9QWOy0miJsgL1Zw"><TiSocialYoutubeCircular size={35} /></a>
                            </div>
                        </Fade>
                    </div>

                </div>
            </div>
        </div>
        <div className="footer-bottom">
            Copyright ©{new Date().getFullYear()} All rights reserved | Designed by Ojehs CP.
        </div>
    </div>
  );
}

export default Footer;