import './Contact.css';
import React from 'react'
import PageHeader from '../../components/PageHeader/PageHeader';
import { FaRegAddressCard } from 'react-icons/fa';
import { BsPhoneVibrate } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import Fade from "react-reveal/Fade";
import { data } from '../../data';

function Contact() {
  return (
    <div className="contact"> 
        <PageHeader title='Contact Us' url='CONTACT' />
        <div className='contact-info'>
          {/* <div className='contact-info-element-wrapper'>
            <Fade bottom>
              <div className='contact-info-element'>
                  <FaRegAddressCard size={30} />
                  <div className='contac-info-title'>
                    Address:
                  </div>
                  <div className='contact-info-detail'>
                    5 Cabot Place, Stoughton MA 02072
                  </div>
              </div>
            </Fade>
          </div> */}
          <div className='contact-info-element-wrapper'>
            <Fade bottom>
              <div className='contact-info-element'>
                  <BsPhoneVibrate size={30} />
                  <div className='contac-info-title'>
                    Phone:
                  </div>
                  <div className='contact-info-detail'>
                    <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
                  </div>
              </div>
            </Fade>
          </div>
          <div className='contact-info-element-wrapper'>
            <Fade bottom>
              <div className='contact-info-element'>
                  <MdOutlineEmail size={30} />
                  <div className='contac-info-title'>
                    Email:
                  </div>
                  <div className='contact-info-detail'>
                    <a href="mailto:support@abttrans.com">support@abttrans.com</a>
                  </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className='copntact-form-area-wrapper'>
          <div className='contact-form-area'>
            <Fade bottom>
              <div className='contact-form-description'>
                  If you got any questions <br/>please do not hesitate to send us a message
              </div>
            </Fade>
            <div className='contact-form'>
              <div><input type='text' placeholder='Your Name' /></div>
              <div><input type='email' placeholder='Your Email' /></div>
              <div><input type='text' placeholder='Subject' /></div>
              <div><textarea placeholder='Message' /></div>
              <button  className="slider-button form-button">
                Send Message
              </button>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Contact;