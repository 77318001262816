import React from 'react'
import RequestForm from '../../components/RequestForm/RequestForm';

function Request() {
  return (
    <div className="request"> 
        <RequestForm />
    </div>
  );
}

export default Request;