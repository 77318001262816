import React from "react";
import './Header.css';
import { FaTelegramPlane, FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { data } from "../../data";

function Header() {
  return (
    <div className="header">
        <div className="header-top" >
            <div className="logo">
                <Link to='/'><img src="./img/logo.png" alt="ABT-LOGO"  width={135}/></Link>            
            </div>
            <div className="header-left">
                <div className="email-wrapper">
                    <div className="email">
                        <FaTelegramPlane size={25} />
                        <div className="email-left">
                            <div className="email-text">Email</div>                    
                            <div className="email-address"><a href="mailto:support@abttrans.com">support@abttrans.com</a></div>
                        </div>
                    </div>
                </div>
                <div className="call-wrapper">
                    <div className="call">
                        <FaPhoneAlt size={20} />
                        <div className="call-left">
                            <div className="call-text">Call</div>                    
                            <div className="call-address"><a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a></div>
                        </div>
                    </div>
                </div>
                <div className="request-button-wrapper">
                    <Link to='/request' className="request-button">Request A Quote</Link>
                </div>
            </div>
        </div>
        <div className="header-bottom">
            <Navbar />
        </div>
    </div>
  );
}

export default Header;