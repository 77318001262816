import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Nemt from './pages/Nemt/Nemt';
import Career from './pages/Career/Career';
import Contact from './pages/Contact/Contact';
import Request from './pages/Request/Request';
import Terms from './pages/Terms/Terms';
import { BsFillTelephoneOutboundFill } from 'react-icons/bs';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <a href="tel:+1(617) 313-7070"><div className='phone-absolute'style={{color:"white"}}><BsFillTelephoneOutboundFill /> </div></a>
        <Header />
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/about" element={<About />}/>
            <Route path="/services" element={<Services />}/>
            <Route path="/nemt" element={<Nemt />}/>
            <Route path="/career" element={<Career />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/request" element={<Request />}/>
            <Route path="/terms" element={<Terms />}/>
          </Routes>
          <Footer />
      </BrowserRouter>  
    </div>
  );
}

export default App;
