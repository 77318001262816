import React, { useState } from "react";
import './Home.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "../../components/Slider/Slider/Sider";
import CollaborationIcon from "../../components/Icons/collaboration_icon";
import { Link } from "react-router-dom";
import FocusedIcon from "../../components/Icons/focused_icon";
import Services from "../../components/Services/Services";
import Fade from "react-reveal/Fade";
import RequestForm from "../../components/RequestForm/RequestForm";
import TestiMonials from "../../components/TestiMonial/TestiMonial";
import ReliableIcon from "../../components/Icons/reliable-icon";
import CompetenceIcon from "../../components/Icons/competence_icon";

function Home() {
    const [color, setColor] = useState("#1b9ce3");
    const [color1, setColor1] = useState("#1b9ce3");
    const [color2, setColor2] = useState("#1b9ce3");
    const [color3, setColor3] = useState("#1b9ce3");
    const [colorOption, setColorOption] = useState(0)
    const [colorOption1, setColorOption1] = useState(0)
    const [colorOption2, setColorOption2] = useState(0)
    const [colorOption3, setColorOption3] = useState(0)
    const setHoverColor = () => {
        setColor('white');
        setColorOption(1)
    }

    const setHoverColor1 = () => {
        setColor1('white');
        setColorOption1(1)
    }

    const setHoverColor2 = () => {
        setColor2('white');
        setColorOption2(1)
    }

    const setHoverColor3 = () => {
        setColor3('white');
        setColorOption3(1)
    }

    const setOriginColor = () => {
        setColor("#1b9ce3");
        setColorOption(0);
    }
    
    const setOriginColor1 = () => {
        setColor1("#1b9ce3");
        setColorOption1(0);
    }

    const setOriginColor2 = () => {
        setColor2("#1b9ce3");
        setColorOption2(0);
    }

    const setOriginColor3 = () => {
        setColor3("#1b9ce3");
        setColorOption3(0);
    }

  return (
    <div className="home" >
        <Slider />
        <div className="why-us">
            <div className="why-us-left">
                <div className="why-us-content">
                    <Fade bottom>
                        <div className="why-us-left-title">
                            Why Us?
                        </div>
                    </Fade>
                    
                    <div className="why-us-content-part">
                        <Fade bottom>
                            <div className="why-us-element" onMouseOver={setHoverColor} onMouseLeave={setOriginColor}>
                                <div><CollaborationIcon color={color}/></div>
                                <div className={colorOption===0?`why-us-element-title`:`why-us-element-title white-element`}>
                                    Safety First
                                </div>
                                <div className={colorOption===0?`why-us-element-text`:`why-us-element-text white-element`}>
                                    We put safety first. Our customers, the school children, and our employees are the most important parts of our business. Thus, we place their safety above every other thing that we do.
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom>
                        <div className="why-us-element" onMouseOver={setHoverColor1} onMouseLeave={setOriginColor1}>
                            <div><FocusedIcon color={color1}/></div>
                            <div className={colorOption1===0?`why-us-element-title`:`why-us-element-title white-element`}>
                                Focused
                            </div>
                            <div className={colorOption1===0?`why-us-element-text`:`why-us-element-text white-element`}>
                                We focus on providing quality transport for patients and school children of all kinds with unbeatable service delivery, safety, and care for our customers.
                            </div>
                        </div>
                        </Fade>
                    </div>
                    
                    <div className="why-us-content-part">
                        <Fade bottom>
                        <div className="why-us-element" onMouseOver={setHoverColor2} onMouseLeave={setOriginColor2}>
                            <div><ReliableIcon color={color2}/></div>
                            <div className={colorOption2===0?`why-us-element-title`:`why-us-element-title white-element`}>
                                Reliability
                            </div>
                            <div className={colorOption2===0?`why-us-element-text`:`why-us-element-text white-element`}>
                                Every day, we show up and get our clients moving. We keep to every schedule. No matter how tight it is, we won't leave you hanging.
                            </div>
                        </div>
                        </Fade>
                        <Fade bottom>
                        <div className="why-us-element" onMouseOver={setHoverColor3} onMouseLeave={setOriginColor3}>
                            <div><CompetenceIcon color={color3}/></div>
                            <div className={colorOption3===0?`why-us-element-title`:`why-us-element-title white-element`}>
                                Competence
                            </div>
                            <div className={colorOption3===0?`why-us-element-text`:`why-us-element-text white-element`}>
                                Our team of professionals are highly skilled, well trained, continuously improving, unfailing, and with over 15 years of experience in the industry.
                            </div>
                        </div>
                        </Fade>
                    </div>
                    
                </div>
            </div>
            <div className="why-us-right-content">
                 <Fade bottom>
                <div className="why-us-right">                
                    <img src="./img/home.jpeg" alt="Image" width="400px"/>
                    <div className={`why-us-element-title`} style={{marginTop:20}}>
                        Welcome to ABT
                    </div>
                    <div className={`why-us-element-text`} style={{width:350, marginBottom:20}}>
                        We are Boston’s most reliable and most efficient school transport and NEMT service company providing the safest in-city transportation. <br/><br/>We are a fast-growing student transport organization based in Boston, MA. We are focused on providing safe, smooth, and reliable transportation services for all school children, as well as those with special needs, and Nonemergency Medical appointments.
                    </div>
                    <Link to='/about'>
                        <button  className="slider-button about-button">
                            About Us
                        </button>
                    </Link>
                </div>
                </Fade>
            </div>
        </div>
        <Services />
        <RequestForm />
        <TestiMonials />     
    </div>
  );
}

export default Home;