import './Nemt.css';
import React from 'react'
import PageHeader from '../../components/PageHeader/PageHeader';
import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade";

function Nemt() {
  return (
    <div className="nemt"> 
        <PageHeader title='Nonemergency Medical Transport' url='NEMT' />
        <div className="why-us">
            <div className="why-us-left">
                <div className='center-wrapper'>
                    <Fade bottom>
                        <div className='why-us-element-text'>
                        <div className='why-us-left-title'>
                            What is NEMT?
                        </div>
                          Nonemergency Medical Transportation is a type of transportation that is finely designed to help support people 
                          that need medical attention and have appointments at healthcare facilities. 
                          Most of our customers who need our NEMT services require special care which we are capable of providing. 
                          Notwithstanding, we also provide services to transport group members for visitations to hospitals 
                          and other healthcare facilities. This transport service does not cover emergency services 
                          as it is booked on schedules and may not be available outside those scheduled times. 
                          If there are emergencies, we advise that you contact the Emergency Medical Services (EMS) or contact 911.
                        </div>
                    </Fade>
                </div>
                <div className='center-wrapper' style={{marginBottom:25}}>
                    <Fade bottom>
                        <div className='why-us-element-text'>
                            <div className='why-us-element-text why-us-element-subtext'>
                                Why Choose ABT NEMT Services?
                            </div>
                            We offer unparalleled Nonemergency Medical Transport Services to residents of Boston, MA. 
                            Our NEMT Service is specially designed to meet our customers at the points of their needs.<br/> <br/>
                            <div style={{lineHeight:2.5}}>
                              With ABT NEMT Service, you are assured of:<br/>

                              1. Promptness with appointments <br/>

                              2. Care, love, and attention <br/>

                              3. Safety <br/>

                              4. Special service for patients on wheelchairs <br/>

                              5. Reduced cost of medical transportation <br/>

                              6. Door to door service for all transporting clients <br/>

                              7. Smooth, stress-free transport to your desired healthcare facility.
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
            <div className="why-us-right-content">
                 <Fade bottom>
                <div className="why-us-right">                
                    <img src="./img/service3.jpeg" alt="Image" width="400px"/>
                    <div className={`why-us-element-title`} style={{marginTop:20}}>
                      Our Dedicated NEMT Services
                    </div>
                    <div className={`why-us-element-text`} style={{width:350, marginBottom:20, lineHeight: 2.5}}>
                    1. Dental Appointments with your dentist <br/>

                    2. Doctor's Appointments at any medical facility of choice <br/>

                    3. Hospital Discharges. We drive you back to your sweet home <br/>

                    4. Dialysis Treatment. Our care is beyond expectations <br/>

                    5. Rehabilitation Transport. We take you to and from any rehab facility <br/>

                    6. Facilitated Wheelchair Transport for unrivalled comfort <br/>

                    7. Transport to Radiation Facility of choice <br/>

                    8. Transport to Adult Care Centers for Seniors <br/>
                    </div>
                    <Link to='/request'>
                        <button  className="slider-button about-button">
                            Request A Quote
                        </button>
                    </Link>
                </div>
                </Fade>
            </div>
        </div>
    </div>
  );
}

export default Nemt;