import { BiFolderOpen } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import './Services.css';
import Fade from "react-reveal/Fade";

function Services() {
  return (
    <div className="services">
        <Fade bottom>
            <div className='servies-title'>
                Our Services
            </div>
        </Fade>
        <Fade bottom>
            <div className='service-text'>
                As a focused team, our attention never waives as we render our services.
            </div>
        </Fade>
        <div className='services-content-wrapper'>
            <div className='services-content'>
                <div className='service-content-part'>
                    <Fade bottom>
                    <div className='service-element'>
                        <img src="./img/service1.png" alt="Image" />
                        <div style={{padding: 25}}>
                            <div className='service-element-title'>
                                Student Transport
                            </div>
                            <div className='service-text' style={{marginBottom: 107}}>
                                We are experts in student transportation. We radiate excellence as we cruise our high-performance buses through the roads of Boston ferrying your children from their home to school and back home.
                            </div>
                            <Link to='/'>
                                <button  className="slider-button">
                                    <span className="button-text" style={{marginRight:5}}>Read More</span> <BiFolderOpen size={16} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='service-element'>
                        <img src="./img/service2.jpg" alt="Service"/>
                        <div style={{padding: 25}}>
                            <div className='service-element-title'>
                                Special Education Transport
                            </div>
                            <div className='service-text' style={{marginBottom: 78}}>
                                We provides door to door student transport services for school children with special needs. We recognize the challenges associated with special education transportation and our team is adequately trained to pay attention to what matters, the kids.                            </div>
                            <Link to='/'>
                                <button  className="slider-button">
                                    <span className="button-text" style={{marginRight:5}}>Read More</span> <BiFolderOpen size={16} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='service-element'>
                        <img src="./img/service3.jpeg" alt="Image"/>
                        <div style={{padding: 25}}>
                            <div className='service-element-title'>
                                Nonemergency Medical Transport
                            </div>
                            <div className='service-text' style={{marginBottom: 20}}>
                                Never miss your appointments with the doctor. Our Nonemergency Medical Transport services have you covered to make sure that you have a smooth, safe, and attention-healthy transportation to your desired healthcare facility within Messachussett.
                            </div>
                            <Link to='/nemt'>
                                <button  className="slider-button">
                                    <span className="button-text" style={{marginRight:5}}>Read More</span> <BiFolderOpen size={16} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    </Fade>
                </div>
                <div className='service-content-part'>
                    <Fade bottom>
                    <div className='service-element'>
                        <img src="./img/service4.jpg" alt="Image" />
                        <div style={{padding: 25}}>
                            <div className='service-element-title'>
                                Care Giving
                            </div>
                            <div className='service-text' style={{marginBottom: 78}}>
                                We recognize that the children are equally important to us as they are to society and you. We invest enough resources and time to train our personnel on how to care for school children with a focus on students with special needs. Indeed, we are a company that truly cares.
                            </div>
                            <Link to='/'>
                                <button  className="slider-button">
                                    <span className="button-text" style={{marginRight:5}}>Read More</span> <BiFolderOpen size={16} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='service-element'>
                        <img src="./img/service5.jpg" alt="Image"/>
                        <div style={{padding: 25}}>
                            <div className='service-element-title'>
                                Safety Training
                            </div>
                            <div className='service-text' style={{marginBottom: 78}}>
                                As a top-quality student transport company, we value safety in everything that we do. As part of our services, we provide safety training to experts, parents, and children to help them avoid dangers in their daily lives. We focused on building a safe world for the owners of the future.
                            </div>
                            <Link to='/'>
                                <button  className="slider-button">
                                    <span className="button-text" style={{marginRight:5}}>Read More</span> <BiFolderOpen size={16} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='service-element'>
                        <img src="./img/service6.jpeg" alt="Image"/>
                        <div style={{padding: 25}}>
                            <div className='service-element-title'>
                                Charter Bus Services
                            </div>
                            <div className='service-text' style={{marginBottom: 20}}>
                                Perhaps your school needs to get on a field trip to spice the learning process up. Maybe there is a big game ahead or competition and your team has to be there. We are your best bet. We provide bus charter services to convey your team to various destinations and back with safety, care, reliability, and our undying focus on top-quality service delivery.
                            </div>
                            <Link to='/'>
                                <button  className="slider-button">
                                    <span className="button-text" style={{marginRight:5}}>Read More</span> <BiFolderOpen size={16} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    </Fade>
                </div>
                <div className='service-content-part'>
                    <Fade bottom>
                    <div className='service-element'>
                        <img src="./img/hot-shot.png" alt="Image" />
                        <div style={{padding: 25}}>
                            <div className='service-element-title'>
                                Hot Shot Trucking
                            </div>
                            <div className='service-text' style={{marginBottom: 78}}>
                            Hot Shot Trucking is your solution for smaller, time-sensitive loads that need to be delivered ASAP. You can call on <a href="tel:+1(617) 313-7070">ABT-America Brightness Transportation</a> when you want the job done right and done quickly. We pride ourselves on service that is fast, reliable, and affordable.
                            </div>
                            <Link to='/'>
                                <button  className="slider-button">
                                    <span className="button-text" style={{marginRight:5}}>Read More</span> <BiFolderOpen size={16} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='service-element'>
                        <img src="./img/semi.jpg" alt="Image" />
                        <div style={{padding: 25}}>
                            <div className='service-element-title'>
                                Hot Shot Trucking
                            </div>
                            <div className='service-text' style={{marginBottom: 78}}>
                            Hot Shot Trucking is your solution for smaller, time-sensitive loads that need to be delivered ASAP. You can call on <a href="tel:+1(617) 313-7070">ABT-America Brightness Transportation</a> when you want the job done right and done quickly. We pride ourselves on service that is fast, reliable, and affordable.
                            </div>
                            <Link to='/'>
                                <button  className="slider-button">
                                    <span className="button-text" style={{marginRight:5}}>Read More</span> <BiFolderOpen size={16} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Services;