import React from 'react';
import './Slider.css';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade";


function Slider1() {
    return (
        <CarouselProvider
        naturalSlideWidth={100}
        // naturalSlideHeight={34}
        isIntrinsicHeight
        totalSlides={3}
        infinite
        isPlaying
      >
        <Slider>
          <Slide index={0}>
            <div className="slider slider-1" >
              <Fade bottom>
              <div className='slider-wrapper'>              
                  <div className="slider-header">SAFE.RELIABLE.</div>
                  <div className="silder-title">School Transport</div>                  
                  <Link to='/services'>
                    <button  className="slider-button">
                      Our Services
                    </button>
                  </Link>
              </div>
              </Fade>
            </div>
          </Slide>
          <Slide index={1}>
            <div className="slider slider-2"  >
              <Fade bottom>
              <div className='slider-wrapper'>              
                  <div className="slider-header">CARING.PATIENT.</div>
                  <div className="silder-title">Special Education <br/> Transport.</div>                  
                  <Link to='/services'>
                    <button  className="slider-button">
                      Our Services
                    </button>
                  </Link>
              </div>
              </Fade>
            </div>
          </Slide>
          <Slide index={2}>
            <div className="slider slider-3"  >
              <Fade bottom>
              <div className='slider-wrapper'>              
                  <div className="slider-header">LOVING.TIMELY.</div>
                  <div className="silder-title">Nonemergency <br/> Medical Transport</div>                  
                  <Link to='/services'>
                    <button  className="slider-button">
                      Our Services
                    </button>
                  </Link>
              </div>
              </Fade>
            </div>
          </Slide>
        </Slider>
      </CarouselProvider>
    );
  }
  
  export default Slider1;