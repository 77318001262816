const FocusedICon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width='50px' x="0px" y="0px" viewBox="0 0 100 95.646" enable-background="new 0 0 100 95.646" fill={props.color} >
    <path d="M33.662,58.646c0.257,1.204,0.579,2.376,0.996,3.516H0V0h3.516v58.646H33.662z">
    </path>
    <path d="M47.487,29.557l4.779-11.348l5.123,2.155L55.455,4.974l-12.359,9.369l5.075,2.135l-5.58,13.249l-7.646-8.841l-7.979,15.176  l-8.008-7.754L9.821,49.069l4.066,1.784l6.569-14.909l7.679,7.435l7.711-14.655l4.938,5.716  C42.754,32.474,45.006,30.814,47.487,29.557z">
    </path>
    <path d="M99.466,85.937L83.561,68.424c-0.625-0.684-1.575-0.71-2.376-0.339c-2.116,3.073-4.837,5.619-7.976,7.539  c-0.149,0.645-0.104,1.322,0.378,1.843l15.898,17.5c0.762,0.846,2.083,0.911,2.93,0.143l6.907-6.25  C100.169,88.098,100.228,86.784,99.466,85.937z">
    </path>
    <path d="M66.946,31.471c-11.966-4.141-25.022,2.201-29.163,14.16c-0.863,2.494-1.26,5.013-1.26,7.5  c0,9.505,5.944,18.379,15.439,21.66c11.963,4.141,25.01-2.194,29.149-14.153c0.847-2.494,1.276-5.014,1.276-7.514  C82.389,43.626,76.426,34.746,66.946,31.471z M75.24,58.6c-1.458,4.219-4.466,7.617-8.482,9.558  c-4.023,1.953-8.562,2.227-12.768,0.762c-6.735-2.318-11.253-8.672-11.253-15.788c0-1.843,0.306-3.685,0.928-5.462  c1.449-4.218,4.473-7.617,8.474-9.563c4.026-1.947,8.558-2.22,12.77-0.768c6.732,2.331,11.25,8.678,11.25,15.787  C76.158,54.987,75.853,56.823,75.24,58.6z">
    </path>
    </svg>     
    );
  
  export default FocusedICon;