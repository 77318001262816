import React, { useState } from 'react';
import './RequestForm.css';
import { Parallax, Background } from "react-parallax";
import Fade from "react-reveal/Fade";
import { data } from '../../data';

function RequestForm() {

    const [isSent, setIsSent]= useState(0);

    const sendRequest = () =>{

         const requestBody = {
          query: `
            mutation Request($firstName: String!, $lastName: String!, $email: String!, $service: String!,  $message: String!) {
              request(requestMailInput: {firstName: $firstName, lastName: $lastName, email: $email, service: $service, message: $message}) {
                status
              }
            }
          `,
          variables: {
            firstName: "First Name",
            lastName: " Last Name",
            email: "Email",
            service: "Service",
            message: "Message"
          }
        };
    
        fetch(data.baseUrl, {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed!');
          }
          return res.json();
        })
        .then(resData => {
          console.log("response", resData);
          setIsSent(1);
          })
        .catch(err => {
          console.log("There was an error!", err);
        });
    }
    return (
        <Parallax strength={500}>
            <Background className="custom-bg">
                <div
                style={{
                    height:800,
                    width: 2000,
                    backgroundImage: "url('./img/cta.jpg')",
                }}
                />
            </Background>
            <div className='request-form-wrapper'>
                <div className='request-form'>
                    <div className='center-wrapper'>
                        <div className='request-form-title'>
                            <Fade bottom>
                                Request A Quote Today!
                            </Fade>
                        </div>
                    </div>
                    <div className='center-wrapper'>
                        <div className='request-form-text'>
                            <Fade bottom>
                                Simply reach out to us for your transport needs and we will get back to you in a flash.
                            </Fade>
                        </div>
                        {isSent===1&&<div className='request-form-text message-sent'>
                            <Fade bottom>
                                Mail Sent. Thank you, we will contact you shortly.
                            </Fade>
                        </div>}
                    </div>
                    <div className='center-wrapper'>
                        <div className='request-form-part' >
                            <div className='request-form-element'>
                                <Fade bottom>
                                    <input type='text' placeholder='First Name' />
                                </Fade>                        
                            </div>
                            <div className='request-form-element'>
                                <Fade bottom>
                                    <input type='text' placeholder='Last Name' />
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div className='center-wrapper'>
                        <div className='request-form-part'>
                            <div className='request-form-element'>
                                <Fade bottom>
                                    <select >
                                        <option >
                                            Select Service
                                        </option>
                                        <option>
                                            School Transport
                                        </option>
                                        <option>
                                            Special Ed Transport
                                        </option>
                                        <option>
                                            NEMT
                                        </option>
                                        <option>
                                            Bus Charter
                                        </option>
                                        <option>
                                            Other Services
                                        </option>
                                    </select>
                                </Fade>
                            </div>
                            <div className='request-form-element'>
                                <Fade bottom>
                                    <input type='email' placeholder='Email' style={{marginTop: 20}} />
                                </Fade>                        
                            </div>
                        </div>
                    </div>
                    <div className='center-wrapper'>
                        <div className='request-form-part'>
                            <div className='request-form-element'>
                                <Fade bottom>
                                    <textarea placeholder='Message' />
                                </Fade>
                            </div>
                            <div className='request-form-element'>
                                <Fade bottom>
                                        {isSent===0?<button  className="slider-button quote-button" onClick={sendRequest}>
                                            Request A Quote
                                        </button>:<button disabled  className="slider-button quote-button" onClick={sendRequest}>
                                            Request A Quote
                                        </button>}
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Parallax>
    );
  }
  
  export default RequestForm;