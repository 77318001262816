import './Terms.css';
import React from 'react'
import PageHeader from '../../components/PageHeader/PageHeader';

function Terms() {
  return (
    <div className="terms"> 
        <PageHeader title='Terms of Use' url='TERMS' />
        <div className='terms-content-wrapper'>
          <div className='terms-content'>
            <div className='terms-content-title'>
                The Dos And Don'ts. Take A Peek.
            </div>
            <div className='terms-content-subtitle'>
                A. General
            </div>
            <div className='terms-content-description numnered'>
              1. America Brightness Transport (ABT), herein referred to as “WE”, “US” or “OUR”, operates school buses, charter buses, and Non-Emergency Medical Transport vehicles in compliance with the guidelines of appropriate regulatory authority.<br />
              2. All our drivers are fully trained and with appropriate regulatory approved driving licenses, we serve our customers excellently.<br />
              3. As part of our safety measures, all vehicles are installed with smart security technology, including (but not limited to) GPS tracking and Video Surveillance Systems.<br />
              4. All buses have designated pick up and drop off points which are made known to our customers. Kindly request for the available points of pick up and drop off.<br />
              5. The travel time may vary depending on the number of customers and changes in the designated route.<br />
              6. For school transport clients, it is the responsibility of the parent(s) to ensure that their children are at the appropriate pick-up point at the designated time. Vehicles will not leave pick-up points before schedule. Inclement weather and traffic delays may cause delays to departure times.<br />
              7. Only persons authorized to enter the vehicles will be allowed to use the transport. Parents/guardians are not authorized to enter the vehicles unless asked to do so by any of our staff.<br />
              8. If a parent or guardians has a query relating to the bus service, the first point of contact will always be ABT, secondly the school or healthcare facility. We and parents will ensure any discussions will be respectful and cordial, in line with Education Regulatory Body Parent Contract expectations, to ensure an appropriate resolution is found.<br />
              9. Drop off of Students during the return trip:<br />
              <div className='tips'>
              1. We will issue Guardian Cards to parents of all students of Grade 6 / Year 7 and below. The Guardian Card is required to receive the student at drop off points. Each student can be received by the parents, relatives, parents of other students, maids or any other adult authorized with the Guardian card. If, after a substantial waiting period, no authorized adult is available to receive a student/pupil, such a student/pupil will be returned to the school where the parents may go and pick them.  
              <br />2. In certain cases, where parents advise our staff, from their balconies/residence, to drop the students, the parent must sign an undertaking that it is acceptable to them to drop off the students if they are visible to the staff. If such an undertaking is not given and an adult is not available to receive the student, they will be returned to the school.  
              <br />3. If Grades 1 to 3 / Year 2 to 4 students have older siblings (Grade 4 / Year 5 and above) traveling with them and a signed undertaking has been provided that they can be dropped in the care of their older sibling, this will be permitted as approved by the parents.
              <br />4. If the students of Grades 4 to 6 / Year 5 to 7 are to be dropped without the presence of an adult, parents must sign a consent form accordingly.
              <br />5. If the above points are not strictly followed by any parent, the students will be brought back to school and it will be the responsibility of the parents to collect the students from the school at their cost.  
              <br />6. A written request, signed by the parent/guardian, for reasons of safety and security, is required if a drop-off other than usual is requested.
              </div>
              10. Parents are required to inform the Driver or any appropriate staff if a student is absent on a particular day. If a student does not want to use the return trip on any particular day the parent should communicate this in writing or send an email to us.
              <br />11. In case of a change of residence, our service provisions shall be revisited.
              <br />12. Students with contagious diseases are not permitted to travel in our vehicles which contain other students. In the case of NEMT services, patients with contagious diseases will be handled with known safety measures to ensure that our drivers and personnel are safe from contagions.
              <br />13. Eating and drinking inside our vehicles other than water will not be permitted.
              <br />14. We reserve the right to decline the provision of service.
              <br />15. All our vehicles, drivers, and passengers are insured. In case of any claim due to an accident, our liability is limited to the comprehensive compensation paid by the insurance company, as per the terms of the insurance policy.
              <br />16. Parents or guardians shall compensate the company for any damages caused or sustained on the vehicles or to other travelers as a result of inappropriate behavior or actions by their child/ward.
              <br />17. We may use email addresses and mobile numbers provided for conveying messages, sending related circulars, and for marketing purposes.
            </div>
            <div className='terms-content-subtitle'>
              B. Registration, Invoices and Payments
            </div>
            <div className='terms-content-description numnered'>
              1. Every client requiring our services must apply at their school (for school transport only), contact us or request a quote from us directly.
              <br />2. Once we receive your request or query, we will respond to them as soon as possible through email, phone calls or any known means convenient to us and our customers.
              <br />3. In the case of new admissions during the term, after the 15th day of the beginning of the term, fees will be charged on pro-rata from the date of the start of service.
              <br />4. Kindly inform us at the time of registration if payment is to be made by the parent employer. All companies must make the payment by the 10th of the first month of the term. If this date is missed, our services to such customers will be terminated.
              <br />5. Invoices can be collected from the school (for school transport only) or emailed to the registered email address. If payment was made at our physical address, an invoice will be issued at the time of payment.
              <br />6. The individual proforma invoice shall be issued to those parents who will require reimbursement from their employer, in cases where the parent’s employer is the paying client.
            </div>
            <div className='terms-content-subtitle'>
              C. Transport Discontinuation and Fees Refund
            </div>
            <div className='terms-content-description numnered'>
              1. Service discontinuation can only be applied at the end of an academic term. If any student is withdrawn from our services before this time, no refund will be provided for any unused period of a term.
              <br />2. In case of end of term discontinuation due to transfer from school at least two weeks’ written notice should be given to any of our representatives with a copy of the transfer certificate.
              <br />3. Service fees shall be paid until the month the student leaves the school (inclusive of the month). If the payment is made for the full year a refund will be made on unused school terms.
              <br />4. In cases of temporary discontinuation due to long leave for more than a month on medical grounds etc, a letter should be submitted to us with the Principal’s knowledge for consideration.
              <br />5. Fee refunds are only applicable when fees for more than one term have been paid and services are not required for the succeeding term(s).
              <br />6. In case of temporary discontinuation on medical grounds, the fee paid may be adjusted for the next term upon approval.
              <br />7. Discontinuation for any reason other than at the end of the academic year may attract a service charge which will also be applicable for the return of post-dated cheques.
              <br />8. Fee refunds will not be made if students are temporarily suspended, for any reason, from using our service.
              <br />9. Refunds shall be made only through the account payee cheques (in the name of the parent who had initially paid, or any person authorized by the parent) and not in cash. In case of payment done from a company, refunds will be made to the company.
              <br />10. Any adjustment for fee waiver or change of area shall be done before the third working day of the following term.
            </div>
            <div className='terms-content-subtitle'>
              D. Area Change
            </div>
            <div className='terms-content-description'>
              Clients must contact us in case of an Area Change or communicate this concern with the school (for school transport only). We will inform parents/guardians of the availability of seats and routes as regards their new area.
            </div>
            <div className='terms-content-subtitle'>
              E. Data Privacy
            </div>
            <div className='terms-content-description numnered' >
              1. We may collect basic personal information from you, in order to keep you informed with information, at your request. At any time, you can request to be removed from our contact database, and you will not be contacted again.
              <br />2. Collected data will be shared with drivers and personnel that would handle the customers directly to ensure that professional consideration is made in various cases of health challenges.
              <br />3. Contact details collected will be used as recipients of our newsletters, notifications, and other marketing purposes.
              <br />4. Collected data may be shared with appropriate school or healthcare facilities.
              <br />5. From time to time, we may pull telephone calls through to you for marketing purposes but will never use automated calling systems. We will only contact you in this way if we are satisfied that we have a legitimate interest to do so and we will always check our own marketing preference records before we make any telephone marketing calls to you.
              <br />6. We will not sell, share, or rent information obtained through its website to others in ways different from what is disclosed.
              <br />7. Our website contains links to other sites. We will not be responsible for the privacy practices of such other sites. We encourage users to be aware when they leave our website and to read the privacy statements of each and every website that collects personally identifiable information. This Terms of Service applies solely to information collected by our website.
              <br />8. We will only ever use your personal data if it is satisfied that it is lawful and fair to do so. We will never sell your personal data or share it with third parties who might use it for their own commercial purposes.
              <br />9. Under certain circumstances, by law, you have the right to:
              <div className='tips'>
                1. Request access to your personal information. This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.        
                <br />2. Ask us to correct any information that we hold about you that is incorrect, incomplete or inaccurate.
                <br />3. Ask us to erase your personal information from our systems where there is no legitimate reason for us to continue holding it.
                <br />4. Object to our use of your personal information to further our legitimate interests (or those of a third party) or where it is using your personal information for direct marketing purposes.
                <br />5. Ask us to restrict or suspend the use of your personal information.
                <br />6. Ask us to transfer your personal information to another person or organization.
              </div>
            </div>
            <div className='terms-content-subtitle'>
              F. Use of Cookies
            </div>
            <div className='terms-content-description'>
              We use “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive 
              to help us improve your access to our websites and identify repeat visitors to our sites. 
              For instance, when we use a cookie to identify you, you would not have to log in a password more than once, 
              thereby saving time while on our site. Cookies can also enable us to track and target the interests of our 
              users to enhance the experience on our site. Cookies do not damage your computer, 
              and usage of a cookie is in no way linked to any personally identifiable information on our site. 
              Most web browsers automatically accept cookies, 
              but you can usually modify your browser setting to decline cookies if you prefer.
            </div>
            <div className='terms-content-subtitle'>
              G. Changes to This Terms of Use
            </div>
            <div className='terms-content-description'>
              If, at any point, we make changes to these Terms of Use, we will post any such changes accordingly.
            </div>
          </div>
        </div>
    </div>
  );
}

export default Terms;