import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import { GrMenu } from "react-icons/gr";
import MenuIcon from '../Icons/menu_icon';

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)


  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
        window.removeEventListener('resize', changeWidth)
    }

  }, [])

  return (
    <nav>
      {(toggleMenu || screenWidth > 675) && (
      <ul className="list">
      <li className="items" onClick={toggleNav}><Link to='/'>Home</Link> </li>
      <li className="items" onClick={toggleNav}><Link to='/about'>About</Link> </li>
      <li className="items" onClick={toggleNav}><Link to='/services'>Services</Link> </li>
      <li className="items" onClick={toggleNav}><Link to='/nemt'>NEMT</Link> </li>
      <li className="items" onClick={toggleNav}><Link to='/career'>Career</Link> </li>
      <li className="items" onClick={toggleNav}><Link to='/contact'>Contact</Link></li>
    </ul>
      )}

      {!(toggleMenu || screenWidth > 675)&&<button onClick={toggleNav} className="btn btn-center"><MenuIcon  />MENU</button>}
    </nav>
  )
}